.openDownLeft {
  -webkit-animation-name: openDownLeft;
  animation-name: openDownLeft;
}
@-webkit-keyframes openDownLeft {
  0% {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  100% {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
    -webkit-transform: rotate(-110deg);
    transform: rotate(-110deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
}
@keyframes openDownLeft {
  0% {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  100% {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
    -webkit-transform: rotate(-110deg);
    transform: rotate(-110deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
}
