.magic {
  -webkit-animation-name: magic;
  animation-name: magic;
}
@-webkit-keyframes magic {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 200%;
    transform-origin: 100% 200%;
    -webkit-transform: scale(1, 1) rotate(0deg);
    transform: scale(1, 1) rotate(0deg);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 200% 500%;
    transform-origin: 200% 500%;
    -webkit-transform: scale(0, 0) rotate(270deg);
    transform: scale(0, 0) rotate(270deg);
  }
}
@keyframes magic {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 200%;
    transform-origin: 100% 200%;
    -webkit-transform: scale(1, 1) rotate(0deg);
    transform: scale(1, 1) rotate(0deg);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 200% 500%;
    transform-origin: 200% 500%;
    -webkit-transform: scale(0, 0) rotate(270deg);
    transform: scale(0, 0) rotate(270deg);
  }
}
