.spaceOutUp {
  -webkit-animation-name: spaceOutUp;
  animation-name: spaceOutUp;
}
@-webkit-keyframes spaceOutUp {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale(1) translate(0%, 0%);
    transform: scale(1) translate(0%, 0%);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale(0.2) translate(0%, -200%);
    transform: scale(0.2) translate(0%, -200%);
  }
}
@keyframes spaceOutUp {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale(1) translate(0%, 0%);
    transform: scale(1) translate(0%, 0%);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale(0.2) translate(0%, -200%);
    transform: scale(0.2) translate(0%, -200%);
  }
}
