.tinLeftOut {
  -webkit-animation-name: tinLeftOut;
  animation-name: tinLeftOut;
}
@-webkit-keyframes tinLeftOut {
  0%,
  20%,
  40%,
  50% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translateX(0);
    transform: scale(1, 1) translateX(0);
  }

  10%,
  30% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1) translateX(0);
    transform: scale(1.1, 1.1) translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1, 1) translateX(-900%);
    transform: scale(1, 1) translateX(-900%);
  }
}
@keyframes tinLeftOut {
  0%,
  20%,
  40%,
  50% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translateX(0);
    transform: scale(1, 1) translateX(0);
  }

  10%,
  30% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1) translateX(0);
    transform: scale(1.1, 1.1) translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1, 1) translateX(-900%);
    transform: scale(1, 1) translateX(-900%);
  }
}
