.bombRightOut {
  -webkit-animation-name: bombRightOut;
  animation-name: bombRightOut;
}
@-webkit-keyframes bombRightOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }

  50% {
    opacity: 1;
    -webkit-transform-origin: 200% 50%;
    transform-origin: 200% 50%;
    -webkit-transform: rotate(160deg);
    transform: rotate(160deg);
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 200% 50%;
    transform-origin: 200% 50%;
    -webkit-transform: rotate(160deg);
    transform: rotate(160deg);
    -webkit-filter: blur(20px);
    filter: blur(20px);
  }
}
@keyframes bombRightOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }

  50% {
    opacity: 1;
    -webkit-transform-origin: 200% 50%;
    transform-origin: 200% 50%;
    -webkit-transform: rotate(160deg);
    transform: rotate(160deg);
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 200% 50%;
    transform-origin: 200% 50%;
    -webkit-transform: rotate(160deg);
    transform: rotate(160deg);
    -webkit-filter: blur(20px);
    filter: blur(20px);
  }
}
