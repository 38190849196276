.perspectiveLeft {
  -webkit-animation-name: perspectiveLeft;
  animation-name: perspectiveLeft;
}
@-webkit-keyframes perspectiveLeft {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: perspective(800px) rotateY(0deg);
    transform: perspective(800px) rotateY(0deg);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: perspective(800px) rotateY(-180deg);
    transform: perspective(800px) rotateY(-180deg);
  }
}
@keyframes perspectiveLeft {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: perspective(800px) rotateY(0deg);
    transform: perspective(800px) rotateY(0deg);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: perspective(800px) rotateY(-180deg);
    transform: perspective(800px) rotateY(-180deg);
  }
}
