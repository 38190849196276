.perspectiveUp {
  -webkit-animation-name: perspectiveUp;
  animation-name: perspectiveUp;
}
@-webkit-keyframes perspectiveUp {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: perspective(800px) rotateX(0deg);
    transform: perspective(800px) rotateX(0deg);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: perspective(800px) rotateX(180deg);
    transform: perspective(800px) rotateX(180deg);
  }
}
@keyframes perspectiveUp {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: perspective(800px) rotateX(0deg);
    transform: perspective(800px) rotateX(0deg);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: perspective(800px) rotateX(180deg);
    transform: perspective(800px) rotateX(180deg);
  }
}
