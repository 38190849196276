.tinUpIn {
  -webkit-animation-name: tinUpIn;
  animation-name: tinUpIn;
}
@-webkit-keyframes tinUpIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(1, 1) translateY(-900%);
    transform: scale(1, 1) translateY(-900%);
  }

  50%,
  70%,
  90% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1) translateY(0);
    transform: scale(1.1, 1.1) translateY(0);
  }

  60%,
  80%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes tinUpIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(1, 1) translateY(-900%);
    transform: scale(1, 1) translateY(-900%);
  }

  50%,
  70%,
  90% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1) translateY(0);
    transform: scale(1.1, 1.1) translateY(0);
  }

  60%,
  80%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
}
