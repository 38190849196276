.holeOut {
  -webkit-animation-name: holeOut;
  animation-name: holeOut;
}
@-webkit-keyframes holeOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1) rotateY(0deg);
    transform: scale(1, 1) rotateY(0deg);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0) rotateY(180deg);
    transform: scale(0, 0) rotateY(180deg);
  }
}
@keyframes holeOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1) rotateY(0deg);
    transform: scale(1, 1) rotateY(0deg);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0) rotateY(180deg);
    transform: scale(0, 0) rotateY(180deg);
  }
}
