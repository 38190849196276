.tinRightIn {
  -webkit-animation-name: tinRightIn;
  animation-name: tinRightIn;
}
@-webkit-keyframes tinRightIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(1, 1) translateX(900%);
    transform: scale(1, 1) translateX(900%);
  }

  50%,
  70%,
  90% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1) translateX(0);
    transform: scale(1.1, 1.1) translateX(0);
  }

  60%,
  80%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translateX(0);
    transform: scale(1, 1) translateX(0);
  }
}
@keyframes tinRightIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(1, 1) translateX(900%);
    transform: scale(1, 1) translateX(900%);
  }

  50%,
  70%,
  90% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1) translateX(0);
    transform: scale(1.1, 1.1) translateX(0);
  }

  60%,
  80%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translateX(0);
    transform: scale(1, 1) translateX(0);
  }
}
