.slideUpReturn {
  -webkit-animation-name: slideUpReturn;
  animation-name: slideUpReturn;
}
@-webkit-keyframes slideUpReturn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@keyframes slideUpReturn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
