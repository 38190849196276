.slideLeftReturn {
  -webkit-animation-name: slideLeftReturn;
  animation-name: slideLeftReturn;
}
@-webkit-keyframes slideLeftReturn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes slideLeftReturn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
