.twisterInUp {
  -webkit-animation-name: twisterInUp;
  animation-name: twisterInUp;
}
@-webkit-keyframes twisterInUp {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 0) rotate(360deg) translateY(100%);
    transform: scale(0, 0) rotate(360deg) translateY(100%);
  }

  30% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 0) rotate(360deg) translateY(100%);
    transform: scale(0, 0) rotate(360deg) translateY(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(1, 1) rotate(0deg) translateY(0);
    transform: scale(1, 1) rotate(0deg) translateY(0);
  }
}
@keyframes twisterInUp {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 0) rotate(360deg) translateY(100%);
    transform: scale(0, 0) rotate(360deg) translateY(100%);
  }

  30% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 0) rotate(360deg) translateY(100%);
    transform: scale(0, 0) rotate(360deg) translateY(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(1, 1) rotate(0deg) translateY(0);
    transform: scale(1, 1) rotate(0deg) translateY(0);
  }
}
