.slideRightReturn {
  -webkit-animation-name: slideRightReturn;
  animation-name: slideRightReturn;
}
@-webkit-keyframes slideRightReturn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes slideRightReturn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
