.swap {
  -webkit-animation-name: swap;
  animation-name: swap;
}
@-webkit-keyframes swap {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scale(0, 0) translate(-700px, 0px);
    transform: scale(0, 0) translate(-700px, 0px);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scale(1, 1) translate(0px, 0px);
    transform: scale(1, 1) translate(0px, 0px);
  }
}
@keyframes swap {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scale(0, 0) translate(-700px, 0px);
    transform: scale(0, 0) translate(-700px, 0px);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scale(1, 1) translate(0px, 0px);
    transform: scale(1, 1) translate(0px, 0px);
  }
}
