.slideDownReturn {
  -webkit-animation-name: slideDownReturn;
  animation-name: slideDownReturn;
}
@-webkit-keyframes slideDownReturn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@keyframes slideDownReturn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
